export default class FormComponent {
    constructor(){
        this.recaptchaRenders = {};
    }
    initGlobal(){
        this._ajaxForm();
        this._ajaxFormMessage();
        this._goForm();
    }
    initView(){
        if($().parsley)
        {
            console.log('formcomponent initialize parsley');
            $("[data-form],[data-form-message],[data-form-go]").parsley({
                //excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden",
                errorsContainer: function(pEle) {

                    if(pEle.$element.is('select') && pEle.$element.next('.select2').length > 0)
                    {
                        if(pEle.$element.next('select2errorsContainer').length == 0)
                        {
                            pEle.$element.next('.select2').after('<div class="select2errorsContainer"></div>');
                        }
                        return pEle.$element.next().next();
                    }

                }
            });
        }

        // générer les recaptcha
        $('[data-form], [data-form-message]').find('[data-recaptcha]').each((i, element) => {
            let $this = $(element);
            if($this.find('.grecaptcha-badge').length > 0) return true;

            let $form = $this.parents('form[data-form-message], form[data-form]');

            let command = $form.is('[data-form-message]') ? 'formMessage' : 'form';

            $this.attr('id', 'recaptcha-'+command+'-'+this._createUID());
            this.recaptchaRenders[$this.attr('id')] = grecaptcha.render($this.attr('id'),{
                "sitekey": data.recaptchaKey,
                "badge": "inline",
                "type": "image",
                "size": "invisible",
                "callback": () => {
                    this._formSubmit(command, $form[0]);
                }
            });
        });
    }

    _goForm(){
        $('body').on("submit", "form[data-form-go]", (e) => {
            e.preventDefault();
            let $form = $(e.currentTarget);

            window.barba.go($form.attr('action')+'?'+$form.serialize());
        });
    }

    _ajaxForm(){
        $('body').on("submit", "form[data-form]", (e) =>{
            e.preventDefault();
            let $form = $(e.currentTarget);
            if($form.find('[data-recaptcha]').length > 0)
            {
                grecaptcha.execute(parseInt(this.recaptchaRenders[$form.find('[data-recaptcha]').attr('id')]));
            }
            else
            {
                this._formSubmit('form',e.currentTarget)
            }
        });
    };

    _ajaxFormMessage(){
        $('body').on("submit", "form[data-form-message]", (e) => {
            e.preventDefault();
            let $form = $(e.currentTarget);
            if($form.find('[data-recaptcha]').length > 0)
            {
                grecaptcha.execute(parseInt(this.recaptchaRenders[$form.find('[data-recaptcha]').attr('id')]));
            }
            else
            {
                this._formSubmit('formMessage',e.currentTarget)
            }
        });
    }

    _formSubmit(command, el){
        let $form = $(el);
        let name = $form.data('form-message') ? $form.data('form-message') : $form.data('form');
        let method = $form.attr('method');
        //let formData = $form.serialize();
        let formData = new FormData(el);

        if(method == 'get')
        {
            formData = $form.serialize();
        }

        console.log('method', method, formData, $form.serialize());

        if($form.find('[data-recaptcha]').length == 0 ||true){
            if(name != '')
            {
                window.appEvent.trigger(command+':'+name+':process',[$form]);
            }
            window.appEvent.trigger(command+':ajax:process',[$form]);
        }

        console.log('formData', formData);

        $.ajax({
            url: $form.attr('action'),
            type: method,
            data: formData,
            success: (response) => {
                if($form.find('[data-recaptcha]').length > 0){
                    grecaptcha.reset(parseInt(this.recaptchaRenders[$form.find('[data-recaptcha]').attr('id')]));
                }
                if(name != '')
                {
                    window.appEvent.trigger(command+':'+name+':success', [
                        response,
                        $form
                    ]);
                }
                window.appEvent.trigger(command+':ajax:success', [
                    response,
                    $form
                ]);
                console.log('success', command+':'+name+':success');
            },
            error : (xhr, status) => {
                if(name != '')
                {
                    window.appEvent.trigger(command+':'+name+':error',[$form]);
                }
                window.appEvent.trigger(command+':ajax:error',[$form]);

            },
            contentType: false,
            processData:false
        });
    }

    _createUID(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
}
