import BarbaJs from './_barba/barba.js';
import FancyComponent from "./components/FancyComponent";
import ScrollComponent from "./_barba/components/ScrollComponent";
import './views/menuNav.js';

window.initGlobal = function() {
    if (window.navigator.userAgent.match(/MSIE|Trident/) !== null){
        $('body').addClass('is-ie');
    }
    window.menuNav.init();
    window.firstTimeLecteur = true
    
    if (Modernizr.touch === true) {
        $('body').addClass('is-touch').removeClass('is-desktop');
        try { // prevent exception on browsers not supporting DOM styleSheets properly
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }else{
        $('body').addClass('is-desktop').removeClass('is-touch');
    }
    
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.appEvent.resize(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--header-height', `${$('header').outerHeight()}px`);
    }, true).trigger('resize')

    window.globalScroll = new ScrollComponent({
        smooth:false,
        target:'#global',
    });
   
    window.scrollAnimation.init();
    window.scrollAnimation.initGlobal();
    window.scrollAnimation.initView();
    window.scrollAnimation.lazyload();
    window.appEvent.listen('app:transitionGlobal:end',function(){
        window.globalScroll.updateView();
    })


    $(document).off('app:transition:isEnter').on('app:transition:isEnter',function(){
        
        window.scrollAnimation.initView();
        window.scrollAnimation.lazyload();  
        window.globalScroll.updateView();
     
    });
    

  

   
  

};

window.initView = function() {
    window.menuNav.reset();
    window.menuNav.updateCurrentLink();

   
};
$( document ).ready(function() {
    console.log( "ready!" );

});

$(window).on('load', function(){

    window.barba = new BarbaJs();
    window.barba.setDefaultTransition();
    window.barba.init();
    $("body").addClass('loaded');
});
