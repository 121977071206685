import { gsap } from "gsap";

// select2 lang

// $("header").find(".select-lang").select2({
//   theme: "select-lang",
//   minimumResultsForSearch: Infinity,
//   allowClear: 1,
// });

// $("header")
//   .find(".select-lang")
//   .on("change", function () {
//     console.log($(this).val());
//     window.barbaInstance.force($(this).val());
//   });

// $(".select2-container--select-lang").click(function () {
//   $(".select2-selection__arrow").toggleClass("active");
// });

const icon = gsap.timeline({ paused: true, reversed: true });
var upper = document.getElementsByClassName("upper");
var middle = document.getElementsByClassName("middle");
var lower = document.getElementsByClassName("lower");
var filters = document.getElementsByClassName("filters");

icon
  .to(
    upper,
    0.2,
    {
      rotation: 45,
      transformOrigin: "50% 50%",
      y: 8,
      ease: "power2.easeInOut",
    },
    "start"
  )
  .to(middle, 0.2, { autoAlpha: 0 }, "start")
  .to(
    lower,
    0.2,
    {
      rotation: -45,
      transformOrigin: "50% 50%",
      y: -10,
      ease: "power2.easeInOut",
    },
    "start"
  )
  .to(filters, 0.3, { autoAlpha: 1,  xPercent: 100 }, "start");

//ouverture du menu
document.getElementById("openBtn").addEventListener("click", function () {
  icon.reversed()
    ? icon.play() && document.getElementById("openBtn").classList.add("opened")
    : icon.reverse() &&
      document.getElementById("openBtn").classList.remove("opened");

  //   close burger menu
  closeMenu();
});

function closeMenu() {
  document.querySelectorAll(".filters li a").forEach((item) => {
    item.addEventListener("click", (event) => {
      icon.reverse();
      document.getElementById("openBtn").classList.toggle("opened");
    });
  });
}

