
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
window.scrollAnimation = new(function() {
  this.scroller = false;
  this.init = function() {

      gsap.registerPlugin(ScrollTrigger);
      // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
      // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
      if (Modernizr.touch != true && window.globalScroll.smoothScroll) {
           window.appEvent.listen('app:scroll', ScrollTrigger.update);
          this.scroller = $('[data-scroll-container]')[0];
          ScrollTrigger.scrollerProxy("[data-scroll-container]", {
              scrollTop(value) {
                  return arguments.length ? window.globalScroll.smoothScroll.scrollTo(value, 0, 0) : window.globalScroll.smoothScroll.scroll.instance.scroll.y;
              }, // we don't have to define a scrollLeft because we're only scrolling vertically.
              getBoundingClientRect() {
                  return {
                      top: 0,
                      left: 0,
                      width: window.innerWidth,
                      height: window.innerHeight
                  };
              },
              // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
              pinType: "transform"
          });


          // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
          ScrollTrigger.addEventListener("refresh", () => window.globalScroll.smoothScroll.update());
          ScrollTrigger.refresh();

      } else {
          window.appEvent.listen('app:scroll', ScrollTrigger.update);
          this.scroller = $('#global')[0];
          ScrollTrigger.scrollerProxy("#global", {
              scrollTop(value) {
                  return arguments.length ? window.globalScroll.scrollTo(value, 0, 0) : window.globalScroll.y;
              }, // we don't have to define a scrollLeft because we're only scrolling vertically.
              getBoundingClientRect() {
                  return {
                      top: 0,
                      left: 0,
                      width: window.innerWidth,
                      height: window.innerHeight
                  };
              },
              // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
              pinType: "fixed"
          }); 


      }
      // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things



  }
    this.initGlobal = function(){
    

    }

    this.initView = function(){
        
        this.update();

    }
    

    
    
    
   
    this.lazyload = function(){
        gsap.utils.toArray("[data-barba-namespace]:last-child .container").forEach((section, i) => {


          ScrollTrigger.create({
            trigger: section,
            start: "-50% bottom",
            scroller:this.scroller,
            once: true,

            onEnter: () => {
                window.imageLoader.lazy(section).then(function(res){
                    console.log(res,$(section).hasClass('container--horizontal'));
                    window.globalScroll.updateView();

                });
            },
            onEnterBack: () =>{
                window.imageLoader.lazy(section).then(function(res){
                window.globalScroll.updateView();

                });
            }
          });
        });
    }
   
    this.update = function(){
        ScrollTrigger.refresh(true);
        window.globalScroll.updateView();
    }
});
