import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import LocomotiveScroll from 'locomotive-scroll';

export default class ScrollComponent {
    constructor(options = {}) {
        // options
        this.opts = {
            target: "[data-scroll-global]",
            duration:400,
            smooth: false,
            offset: false
        };
        this.opts = Object.assign(this.opts, options);

        this.y = 0;
        this.limit = 0;
        this.scroll =  document.querySelectorAll(this.opts.target)[0];

        // smoothScroll
        this.smoothScroll = false;

        // init
        this._init();
    }

    _touchDetector() {
        if (Modernizr.touch === true)
        {
            // SET STYLE TO BODY
            const body = document.body;
            body.style.overflow = 'hidden';
            body.style.height = '100%';
            // SET STYLE TO SCROLLABLE CONTENT
            const scrollContainer = document.querySelectorAll(this.opts.target)
            scrollContainer[0].style.overflowY = "scroll";
            scrollContainer[0].style.overflowX = "hidden";
            scrollContainer[0].style.width = "100%";
            scrollContainer[0].style.height = "100%";
            this.scroll = scrollContainer[0];
            this._scrollEvent();
        }
        else
        {
            if(this.opts.smooth)
            {
                // locomotivescroll
                document.body.style.overflow = 'hidden';
                document.body.classList.add('overflow-body');
                this.smoothScroll = new LocomotiveScroll({
                    el: document.querySelector('[data-scroll-container]'),
                    scrollbarClass: "scrollbar",
                    smooth: true,
                    tablet:{smooth:true},
                    smartphone:{smooth:true},
                    firefoxMultiplier: 100,
                });

                this.smoothScroll.on("scroll",(e) => {
                    this.y = e.scroll.y;
                    this.limit = e.limit;
                    window.appEvent.trigger('app:scroll', [this.y, this.limit]);
                });
            }
            else
            {
                this._scrollEvent();
            }
        }
    }

    _clickEvents() {
        $('body').on('click', '[data-scrollto]', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let $target = $(e.currentTarget);
            let data = $target.data('scrollto');

            if(data === '')
            {
                data = $target.attr('href');
            }

            this.scrollTo(data);
        })
    }

    _scrollEvent() {
        this.scroll.addEventListener("scroll", (event) => {
            
            this.y = this.scroll.scrollTop;
            this.limit = this.scroll.scrollHeight - window.innerHeight;
            window.appEvent.trigger('app:scroll', [this.y, this.limit]);
        })
    }

    _scrollToPosition(pos, time,offset) {
        console.log('_scrollToPosition', pos,time,offset);

        if(this.opts.smooth && Modernizr.touch != true)
        {
            console.log('_scrollToPosition', pos);

            if(time == 0){
                this.smoothScroll.scrollTo( 'top', {
                    'offset': pos + offset,
                    'callback': function() {
                        // do something...
                    },
                    'duration': 0,
                    'easing': [0.25, 0.00, 0.35, 1.00],
                    'disableLerp': true
                } );
                

            }else{
                this.smoothScroll.scrollTo(  pos, offset, time, [0.25, 0.00, 0.35, 1.00], true, function() {
                    // do something
               });

            }
        }
        else
        {
            if(time == 0)
            {
                gsap.set(this.scroll, {
                    scrollTo: pos-offset
                });
            }
            else
            {
                gsap.to(this.scroll, {
                    duration: time/1000,
                    ease: "power3.out",
                    scrollTo:  pos-offset
                });
            }
        }
    }

    scrollTo(target, time = false, offset = false) {
        // check duration
        if(time === false) time = this.opts.duration;

        // check target

        if(typeof target === 'string')
        {
            if (this.opts.smooth && Modernizr.touch != true){
                target = target
            }else{
                target = $(target).offset().top + $(this.scroll).scrollTop();

            }
        }

        // check offset
        if(offset === false)
        {
            offset = this.opts.offset;
        }

        let newOffset = 0;

        if(offset)
        {
            if(typeof offset === 'string')
            {
                $(offset).each(function() {
                    newOffset += $(this).outerHeight(true);
                    console.log('newOffset', newOffset);
                });

            }
            else if(typeof offset == 'number')
            {
                newOffset = this.opts.offset;

            }
            else if (typeof offset === 'function') {
                newOffset = this.opts.offset();
            }
        }

        

        this._scrollToPosition(target, time,newOffset);
    }

    updateView() {
        if(this.opts.smooth == true && Modernizr.touch !== true)
        {
            this.smoothScroll.update()
            console.log('UPDATE SCROLL')

        }
    }

    initView() {
        this.scrollTo(0, 0,0);
        if(window.location.hash)
        {
            if($(window.location.hash).length > 0)
            {
                this.scrollTo(window.location.hash, 0);
            }
        }
    }

    _init() {
        // touch detector
        this._touchDetector();

        // click events
        this._clickEvents();

    }
}
