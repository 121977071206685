import { gsap } from 'gsap';

import ScreenLoader from '../views/ScreenLoader.js';
window.loadingScreen = new ScreenLoader (.25,.25);


window.barbaDefaultTransition = {
  sync: false,
  name: 'loadingScreen',
  before: function(data) {
    console.log('HELO BEFORE',$(data.next.container))
    var  done = this.async();

    window.loadingScreen.show().then(function(res){
      done()
      $(document).trigger('app:barba:beforeDone');

    });
  },
  beforeLeave: function(data){
    //var  done = this.async();
  },
  afterLeave: function(data){
    gsap.set(data.next.container, {autoAlpha: 0});
  },
  beforeEnter: function(data) {
    var  done = this.async();
    window.imageLoader.load(data.next.container, function(){
        gsap.to(data.current.container, .5, {
          autoAlpha: 0,
          ease:"power4.inOut",
          onComplete: function(){
            done();
            $(document).trigger('app:barba:beforeEnter');
            gsap.set(data.next.container, {autoAlpha: 1});


          }
        });
    });
  },
  enter: function(data){
    $(data.next.container).removeClass('is-pending');
    gsap.set(data.next.container, {autoAlpha: 1});
    appEvent.trigger('app:threejsAnimation:start');
    window.loadingScreen.hide().then(function(){
        $(document).trigger('app:transition:isEnter');
    });

  }

};
