/***************************************************
██╗    ██╗██╗██████╗ ██╗ ██████╗ ██████╗ ███╗   ███╗
██║    ██║██║██╔══██╗██║██╔════╝██╔═══██╗████╗ ████║
██║ █╗ ██║██║██████╔╝██║██║     ██║   ██║██╔████╔██║
██║███╗██║██║██╔══██╗██║██║     ██║   ██║██║╚██╔╝██║
╚███╔███╔╝██║██████╔╝██║╚██████╗╚██████╔╝██║ ╚═╝ ██║
 ╚══╝╚══╝ ╚═╝╚═════╝ ╚═╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝
***************************************************/
let styles = [
    'background: black'
    , 'color: white'
    , 'display: block'

    , 'line-height: 40px'
    , 'text-align: center'
    , 'fontsize: 20px'
    , 'font-weight: lighter'
].join(';');

console.info('%c HANDMADED WITH ❤ BY WIBICOM ', styles);

/****************************************************
*****************************************************
*GESTION DU PUBLIC PATH DYNAMIC ;
*****************************************************
****************************************************/
__webpack_public_path__ = base + '/assets/';
/****************************************************
*****************************************************
*IMPORT GLOBALS DEPENDENCIES FROM NODE MODULES;
*****************************************************
****************************************************/
import $ from 'jquery';
window.$ = window.jQuery = $;

import fancybox from '@fancyapps/fancybox';
import select2 from "select2/dist/js/select2.full.js";
$.fancybox.defaults.backFocus = false;
$.fancybox.defaults.hash = false;
window.data.base = "https://real-lab.com/";

select2();

import './views/scrollAnimation.js';
import './init.js';

import './views/menu.js';

let DEBUG = process.env.NODE_ENV == 'development' ? true : false;
if(!DEBUG){
    
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn","info"];
    for(var i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}
