import { gsap } from "gsap";

window.menuNav = new(function() {
  this.timeline = gsap.timeline({paused:true});
  this.init = function() {
      this.timeline.to('header .main-nav',{
          xPercent:100,
          duration:.3,
          ease: 'power2.inOut',

      },'triggerMenu')
      this.timeline.from('header .main-nav__item a', .6 ,{
          yPercent: 110,
          autoAlpha:0,
          ease: 'power2.inOut',
          stagger: .03,
          delay:.3,

      },'triggerMenu');
      
      
    

      $('header .main-nav__item a').on('click', function (e) {
          if($(this).hasClass('current'))
          {
              $('.main-header .btn-menu').trigger('click');
              e.preventDefault();
              return false;
          }
      });
     

      $(window).off('orientationchange',this.menuResizeHandler).on('orientationchange',this.menuResizeHandler);

      $(window).off('resize',this.menuResizeHandler).on('resize',this.menuResizeHandler).trigger('resize')
      $('.btn-menu').on('click', function(e) {
          e.preventDefault();
          if ($(this).hasClass('open')){
              window.menuNav.hide();
              
              console.log('HELLO')
          } else {
              window.menuNav.show();
              $(".main-header").addClass('open');
          }
      })
      $('header .main-nav').on('click',function(){
          $('.btn-menu').trigger('click');
      })
  }
  this.menuResizeHandler = function(){
      console.log('ici');
      $('.main-header .btn-menu').removeClass('open')
      //gsap.set($('.main-nav-center') ,{clearProps: "all"});
     // gsap.set($('.menu-item a') ,{clearProps: "all"});

      //this.hide();


  }
  this.show = function(){
      
      this.timeline.play()
      $('.main-header .btn-menu').addClass('open')
     

  }
  this.hide = function(){


  return new Promise((resolve)=>{
      if ($('.main-header .btn-menu').hasClass('open') != true){
          resolve(true);
          return;
      }
      else{
          this.timeline.reverse()
          $('.main-header,.btn-menu').removeClass('open')

      }

     

     
  });





  }

  this.reset = function(){


    
      $('.main-header,.btn-menu').removeClass('open')
      gsap.set('header  .main-nav  a', {yPercent: 110,autoAlpha:0});
      gsap.set('header .main-nav',{xPercent:-100})

  }
  this.updateCurrentLink = function(){

    $('header .nav--link').removeClass('current');
    $('header .nav--link').each(function(index){
        let elHref = $(this).prop("href")

        if ( elHref  == document.location.href ){
            $(this).addClass('current');
        }

    })
    }
})
